
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IDashboardItem } from '../types';

@Component
export default class OperCurrentAccount extends Vue {
  @Prop() readonly value: IDashboardItem | undefined;

  select() {
    this.$emit('select')
  }
}
