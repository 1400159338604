
import { ProcessStates } from '@/modules/system/types';
import $ph from '@/plugins/phoenix';
import { AppError } from '@/plugins/phoenix/library';
import settings from '@/plugins/settings';
import { session, system, wallet } from '@/plugins/store';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ACCOUNT_DEFAULT, CURRENCY_DEFAULT, OPER_DEFAULT, PAY_TYPE_DEFAULT, RATE_DEFAULT } from '../store';
import { AccountTypeModes, IAccount, ICurrency, IDashboardItem, INetworkAddress, INetworkSettings, IOperation, IOperationFull, IOperModeSettings, IOperTypeSettings, IPayMethod, IPayMethodSettings, IRate, IRateRequest, OperTypeModes, OperTypePrecheckMode, PaymentMethodType } from '../types';

export enum OperSteps {
  prepare = 'prepare',
  amount = 'amount',
  accounts = 'accounts',
  methods = 'methods',
  putmethod = 'putmethod',
  static = 'static',
  wallets = 'wallets',
  secure = 'secure',
  processing = 'processing',
  background = 'background',
  failed = 'failed',
  success = 'success',
  message = 'message',
}

@Component
export default class OperationDialog extends Vue {
  @Prop() readonly value: any;
  @Prop() readonly operData: any;

  operation: IOperation = OPER_DEFAULT()
  type: IOperTypeSettings | null = null
  operModeSettingsList: Array<IOperModeSettings> = []
  payMethodSettingsList: Array<IPayMethodSettings> = []
  payMethod: IPayMethod | null = null
  payMethodSettings: IPayMethodSettings | null = null
  payMethodEdit: IPayMethod | null = null
  showMethodEdit = false
  storeMethod = false

  redirectVisible = false
  redirectUrl = ''

  inAccount: IAccount = ACCOUNT_DEFAULT()
  outAccount: IAccount = ACCOUNT_DEFAULT()

  accNetAddress: INetworkAddress | null = null
  networks: Array<INetworkSettings> = []

  inCurrency: ICurrency = CURRENCY_DEFAULT()
  outCurrency: ICurrency = CURRENCY_DEFAULT()
  feeCurrency: ICurrency = CURRENCY_DEFAULT()

  inAmount = 0
  outAmount = 0
  isAmountIn = false

  rate: IRate | null = null
  rateColor = 'disabled--text'

  checkCode = ''

  step: OperSteps = OperSteps.prepare
  processing = false
  updating = false
  fatal = false
  rateUpdator: number | null = null

  error = ''
  errCard = ''
  errGeneral = ''
  errAmtIn = ''
  errAmtOut = ''
  errorMessage = ''

  @Watch('value')
  onShow(value: any) {
    if (value) {
      this.fatal = false
      this.step = OperSteps.prepare
      this.error = ''
      this.errCard = ''
      this.errGeneral = ''
      this.checkCode = ''
      this.payMethod = null
      this.rate = { currBuy: 'XXX', currSell: 'XXX', gateCode: 'XXX', realRate: 1, customerRate: 1 }
      this.$nextTick(this.next)
    } else {
      this.stopRateUpdate();
    }
    wallet.refreshBaseData(session.user)
  }

  @Watch('payMethod')
  onPayMethodChange(value: any) {
    this.validate()
  }

  @Watch('operData')
  onOperChange(value: any) {
    this.operation = value ? $ph.clone(value) : OPER_DEFAULT()
    this.inAmount = this.operation.operInAmount
    this.outAmount = this.operation.operOutAmount
    this.isAmountIn = false
  }

  @Watch('inAmount')
  onInAmountChange(value: any) {
    if (!isNaN(value)) {
      this.recalcOut(true)
    }
  }

  @Watch('outAmount')
  onOutAmountChange(value: any) {
    if (!isNaN(value)) {
      this.recalcIn(true)
    }
  }

  get isButtonDisabled(): boolean {
    if (this.step === OperSteps.putmethod) {
      return this.errCard !== ''
    } else {
      return this.errCard !== '' || this.errGeneral !== '' || this.errAmtOut !== '' || this.errAmtIn !== ''
    }
  }

  get scrolledFrameStyle() {
    let style = ''
    style += 'height: 430px; '
    style += 'min-height: 430px; '
    style += 'max-height: 440px; '
    style += 'border-width: 0px; '
    style += 'overflow-y: auto; '
    style += 'padding: 0; '
    style += 'width: 100%; '
    return style
  }

  get usePaymentMethods(): boolean {
    const widget = system.widgetsIndex['widget-operation-' + this.mode.toLowerCase()]
    if (widget && widget.settings) {
      return '' + widget.settings.usePayMethods === 'true'
    }
    return false
  }

  get showBack() {
    return this.step === OperSteps.methods || this.step === OperSteps.putmethod || this.step === OperSteps.secure
  }

  get showClose() {
    return this.step === OperSteps.static
  }

  get showNext() {
    return this.step === OperSteps.amount || this.step === OperSteps.putmethod || this.step === OperSteps.secure || this.step === OperSteps.success || this.step === OperSteps.failed || this.step === OperSteps.background || this.step === OperSteps.wallets
  }

  get isPersistent() {
    return this.step === OperSteps.prepare || this.step === OperSteps.processing
  }

  get visible(): boolean {
    return this.value
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }

  get title() {
    if (this.step === OperSteps.prepare) {
      return ''
    } else if (this.step === OperSteps.methods) {
      return $ph.i18n('payment.methods')
    } else if (this.step === OperSteps.putmethod && this.payMethodSettings) {
      return $ph.i18n('payment.methods.groups.' + this.payMethodSettings.payMethodType)
    } else if (this.operation.operMode === OperTypeModes.Any) {
      return ''
    } else {
      return $ph.i18n('payment.modes.' + this.operation.operMode) + ' ' + this.mainCurrency.currencyName
    }
  }

  get btnCaption() {
    if (this.step === OperSteps.prepare) {
      return ''
    } else if (this.step === OperSteps.putmethod) {
      return 'system.append'
    } else if (this.step === OperSteps.success || this.step === OperSteps.background) {
      return 'system.close'
    } else if (this.step === OperSteps.amount && this.mode === OperTypeModes.Get) {
      return 'system.close'
    } else if (this.step === OperSteps.wallets) {
      return 'system.close'
    } else if (this.step === OperSteps.secure) {
      return 'security.2fa.check.action'
    } else if (this.step === OperSteps.failed) {
      if (this.fatal) {
        return 'system.close'
      } else {
        return 'system.tryagain'
      }
    } else if (this.operation.operMode === OperTypeModes.Any) {
      return ''
    } else {
      return $ph.i18n('payment.buttons.' + this.operation.operMode)
    }
  }

  get payMethodCaption() {
    if (this.mode === OperTypeModes.Sell || this.mode === OperTypeModes.Withdraw) {
      return 'payment.paymentMethod.withdraw'
    } else if (this.mode === OperTypeModes.Send) {
      return 'payment.paymentMethod.wallet'
    } else {
      return 'payment.paymentMethod'
    }
  }

  get mode(): OperTypeModes {
    return this.operation.operMode
  }

  get useCrossRate(): boolean {
    return (this.type && this.type.operUserCrossRate) || false
  }

  get mainAccount(): IAccount {
    return this.operation.operMainCurrency === this.operation.operOutCurrency
      ? this.outAccount
      : this.inAccount
  }

  get secondAccount(): IAccount {
    return this.operation.operMainCurrency === this.operation.operOutCurrency
      ? this.inAccount
      : this.outAccount
  }

  get mainCurrency(): ICurrency {
    return this.operation.operMainCurrency === this.operation.operOutCurrency
      ? this.outCurrency
      : this.inCurrency
  }

  get secondCurrency(): ICurrency {
    return this.operation.operMainCurrency === this.operation.operOutCurrency
      ? this.inCurrency
      : this.outCurrency
  }

  get total(): string {
    if (this.mainCurrency.currencyCode === this.operation.operInCurrency) {
      let total = this.operation.operOutAmount + (this.type!.operTypePrecheck === OperTypePrecheckMode.DebetAmount ? this.operation.operFeeAmount * -1 : this.operation.operFeeAmount)
      total = total < 0 ? 0 : total
      return $ph.format(total, { dec: this.outCurrency.currencyPrecision })
    } else {
      let total = this.operation.operOutAmount + this.operation.operFeeAmount
      total = total < 0 ? 0 : total
      return $ph.format(total, { dec: this.outCurrency.currencyPrecision })
    }
  }

  get balance(): string {
    return $ph.format(this.mainAccount.accountBalAvail, { dec: this.mainCurrency.currencyPrecision })
  }

  get inFormat() {
    return ' 3.' + this.inCurrency.currencyPrecision
  }

  get outFormat() {
    return ' 3.' + this.outCurrency.currencyPrecision
  }

  get rateValue() {
    const rt = this.rate ? this.rate.customerRate : 1
    return '1 ' + this.mainCurrency.currencyCode + ' = ' + $ph.format(rt, { dec: this.mainCurrency.currencyPrecision, trunc: true }) + ' ' + this.secondCurrency.currencyCode
  }

  get feeValue() {
    if (this.operation.operFeeAmount > 0) {
      return $ph.format(this.operation.operFeeAmount, { dec: this.feeCurrency.currencyPrecision, trunc: true }) + ' ' + this.feeCurrency.currencyCode
    } else {
      return '0 ' + this.feeCurrency.currencyCode
    }
  }

  get payMethods() {
    return wallet.payMethods.filter(m =>
      (m.type.payMethodMultiCurrency || m.payMethodCurrency === this.operation.operMainCurrency) &&
      this.payMethodSettingsList.some(i => i.payMethodTypeVariant === m.payMethodTypeVariant)) || []
  }

  get supportMail() {
    if (wallet.company) {
      return wallet.company.supportEmail
    } else {
      return ''
    }
  }

  get showDetailedInfo() {
    return this.type && this.type.operInfoTitle;
  }

  get detailedInfoTitle() {
    return this.type ? this.type.operInfoTitle : null
  }

  get detailedInfoValue() {
    return this.type ? this.type.operInfoValue : null
  }

  get currentAccount(): IDashboardItem | null {
    let accountNumber = ''
    if (this.mode === OperTypeModes.Buy) {
      accountNumber = this.operation.operOutAccount || ''
    } else {
      accountNumber = this.operation.operInAccount || ''
    }

    if (accountNumber !== '') {
      return wallet.dashboard.find(d => d.account && d.account.accountTypeMode === AccountTypeModes.CustomerCurrent && d.account.accountNumber === accountNumber) || null
    }

    return null;
  }

  stopRateUpdate() {
    if (this.rateUpdator) {
      clearInterval(this.rateUpdator)
      this.rateUpdator = null
    }
  }

  async back() {
    this.error = ''
    if (this.step === OperSteps.methods) {
      this.step = OperSteps.amount
    } else if (this.step === OperSteps.putmethod) {
      this.showMethodEdit = false;
      this.step = OperSteps.methods
    } else if (this.step === OperSteps.secure) {
      this.step = OperSteps.amount
    }
  }

  async next() {
    try {
      if (this.step === OperSteps.prepare) {
        const mode = this.operation.operMode

        this.outCurrency = wallet.currIndex[this.operation.operOutCurrency || ''] || CURRENCY_DEFAULT()
        this.inCurrency = wallet.currIndex[this.operation.operInCurrency || ''] || CURRENCY_DEFAULT()

        this.outAccount = wallet.accountIndex[this.operation.operOutAccount || ''] || ACCOUNT_DEFAULT()
        this.inAccount = wallet.accountIndex[this.operation.operInAccount || ''] || ACCOUNT_DEFAULT()

        this.operModeSettingsList = await wallet.getOperationTypeByMode(this.operation)
        if (!this.operModeSettingsList || this.operModeSettingsList.length === 0) {
          throw new AppError('NO_OPER_TYPE', 'Operation type not found')
        }

        this.operation.operTypeCode = this.operModeSettingsList[0].operTypeCode
        this.type = await wallet.getOperationTypeSettings(this.operation)
        if (this.type) {
          this.type.operInfoTitle = this.operModeSettingsList[0].operInfoTitle
          this.type.operInfoValue = this.operModeSettingsList[0].operInfoValue

          if (this.type.operUserCrossRate) {
            if (mode === OperTypeModes.Buy) {
              this.operation.operOutCurrency = ''
              this.operation.operMainCurrency = this.operation.operOutCurrency
            } else {
              this.operation.operInCurrency = ''
              this.operation.operMainCurrency = this.operation.operInCurrency
            }
          }
        }

        this.payMethodSettingsList = await wallet.getPayMethodSettings(this.operation)
        await wallet.refreshPayMethods()
        const mtd = this.payMethods.filter(method => {
          return (mode === OperTypeModes.Buy && method.payMethodFavoriteBuy) ||
            (mode === OperTypeModes.Sell && method.payMethodFavoriteSell) ||
            (mode === OperTypeModes.Send && method.payMethodFavoriteSend) ||
            (mode === OperTypeModes.Get && method.payMethodFavoriteGet) ||
            (mode === OperTypeModes.Topup && method.payMethodFavoriteTopup) ||
            (mode === OperTypeModes.Withdraw && method.payMethodFavoriteWithdraw)
        })

        if (mtd && mtd.length > 0) {
          this.setMethod(mtd[0])
        }

        await this.updateOperationNow()

        if (this.outCurrency.currencyCode !== this.inCurrency.currencyCode && !this.rateUpdator) {
          this.rateUpdator = setInterval(() => { this.updateRate() }, 5000)
        }

        if (mode === OperTypeModes.Get) {
          this.networks = await wallet.getFinanceNetworks(this.outCurrency.currencyCode)
          if (this.networks.length === 0) {
            this.step = OperSteps.message
          } else if (this.networks.length === 1) {
            if (this.inAccount.networks) {
              this.accNetAddress = this.inAccount.networks.find(net => net.networkId === this.networks[0].networkId) || null
            }

            if (this.accNetAddress == null) {
              this.inAccount = await wallet.createNetworkAddress({ networkId: this.networks[0].networkId, networkAddress: '', accountNumber: this.inAccount.accountNumber || '', balance: 0 })
              this.accNetAddress = this.inAccount.networks.find(net => net.networkId === this.networks[0].networkId) || null
            }
            this.step = OperSteps.amount
          } else {
            this.step = OperSteps.wallets
          }
        } else {
          this.step = OperSteps.amount
        }
        this.processing = false
      } else if (this.step === OperSteps.putmethod) {
        this.errCard = ''
        this.storeMethod = false
        this.processing = true
        this.$nextTick(() => { this.storeMethod = true })
      } else if (this.step === OperSteps.wallets) {
        this.visible = false
      } else if (this.step === OperSteps.amount) {
        this.validate()
        if (this.mode === OperTypeModes.Get) {
          this.visible = false
        } else {
          if (this.type!.operType2FA && session.user.checkOper2FA) {
            this.checkCode = ''
            this.step = OperSteps.secure
          } else {
            this.step = OperSteps.processing
            this.processOperation()
          }
        }
      } else if (this.step === OperSteps.secure) {
        this.step = OperSteps.processing
        this.error = ''
        this.processOperation()
      } else if (this.step === OperSteps.failed) {
        if (this.fatal) {
          this.visible = false
        } else {
          if (this.outCurrency.currencyCode !== this.inCurrency.currencyCode && !this.rateUpdator) {
            this.rateUpdator = setInterval(() => { this.updateRate() }, 5000)
          }

          this.error = ''
          this.step = OperSteps.amount
        }
      } else if (this.step === OperSteps.success || this.step === OperSteps.background) {
        this.visible = false;
      }
    } catch (e) {
      const err: any = e
      if (this.step === OperSteps.prepare) {
        if (err.errorCode === 'SYS011') {
          this.step = OperSteps.message
        } else {
          this.error = $ph.i18n('system.errors.FRT003') + ' [' + (err.errorCode || 'SYSTEM') + ']'
          this.errorMessage = $ph.i18n('payment.failed')
          this.step = OperSteps.failed
          this.fatal = true
        }
      } else {
        session.pushError(err)
      }
    }
  }

  async selectNetwork(network: INetworkSettings) {
    this.processing = true
    try {
      if (this.inAccount.networks) {
        this.accNetAddress = this.inAccount.networks.find(net => net.networkId === network.networkId) || null
      }

      if (this.accNetAddress == null) {
        this.inAccount = await wallet.createNetworkAddress({ networkId: network.networkId, networkAddress: '', accountNumber: this.inAccount.accountNumber || '', balance: 0 })
        this.accNetAddress = this.inAccount.networks.find(net => net.networkId === network.networkId) || null
      }

      this.step = OperSteps.amount
    } catch (e) {
      const err: any = e
      if (this.step === OperSteps.prepare) {
        if (err.errorCode === 'SYS011') {
          this.step = OperSteps.message
        } else {
          this.error = $ph.i18n('system.errors.FRT003') + ' [' + (err.errorCode || 'SYSTEM') + ']'
          this.errorMessage = $ph.i18n('payment.failed')
          this.step = OperSteps.failed
          this.fatal = true
        }
      } else {
        session.pushError(err)
      }
    }
    this.processing = false
  }

  validate() {
    this.errGeneral = ''
    this.errAmtOut = ''
    this.errAmtIn = ''

    if ((this.usePaymentMethods || this.mode === 'SND' || this.mode === 'WTH') && !this.payMethod) {
      this.errAmtOut = 'FRT014'
    }

    if (this.useCrossRate && this.mode === 'BUY' && this.operation.operOutCurrency === '') {
      this.errGeneral = 'FRT016'
    } else if (this.useCrossRate && this.mode === 'SLL' && this.operation.operInCurrency === '') {
      this.errGeneral = 'FRT016'
    } else if ((!this.rate || this.rate.realRate === 0) && (this.mode === 'BUY' || this.mode === 'SLL')) {
      this.errGeneral = 'FRT017'
    }

    if (this.type) {
      const precheck = this.type.operTypePrecheck
      if (this.outAccount && this.errAmtOut === '') {
        if (precheck === OperTypePrecheckMode.DebetAmount) {
          this.errAmtOut = this.outAccount.accountBalAvail < this.outAmount ? 'ACC002' : ''
        } else if (precheck === OperTypePrecheckMode.DebetAmountAndFee) {
          this.errAmtOut = this.outAccount.accountBalAvail < (this.outAmount + this.operation.operFeeAmount) ? 'ACC002' : ''
        }
      }

      if (this.mainCurrency.currencyCode === this.outCurrency.currencyCode) {
        if (this.outAmount < this.type.operMinAmount && this.outAmount !== 0) {
          this.errAmtOut = 'FIN006'
        } else if (this.outAmount > this.type.operMaxAmount) {
          this.errAmtOut = 'FIN007'
        }
      } else {
        if (this.inAmount < this.type.operMinAmount && this.inAmount !== 0) {
          this.errAmtIn = 'FIN006'
        } else if (this.inAmount > this.type.operMaxAmount) {
          this.errAmtIn = 'FIN007'
        }
      }
    }

    if (this.errGeneral !== '') {
      this.error = $ph.i18n('system.errors.' + this.errGeneral)
    } else if (this.errAmtIn !== '') {
      this.error = $ph.i18n('system.errors.' + this.errAmtIn)
    } else if (this.errAmtOut !== '') {
      this.error = $ph.i18n('system.errors.' + this.errAmtOut)
    } else {
      this.error = ''
    }
  }

  recalcIn(doUpdate: boolean) {
    if (!this.isAmountIn) {
      if (this.rate === null || this.rate.realRate === 0) {
        this.errGeneral = 'FRT017'
      } else {
        this.inAmount = (this.outAmount / this.rate.realRate).round(this.inCurrency.currencyPrecision)
      }

      if (doUpdate) {
        this.startUpdate()
      }
    }

    this.validate()
  }

  recalcOut(doUpdate: boolean) {
    if (this.isAmountIn) {
      if (this.rate === null || this.rate.realRate === 0) {
        this.errGeneral = 'FRT017'
      } else {
        this.outAmount = (this.inAmount * this.rate.realRate).round(this.outCurrency.currencyPrecision)
      }

      if (doUpdate) {
        this.startUpdate()
      }
    }

    this.validate()
  }

  startUpdate() {
    this.updating = true;
    $ph.delay('OPER_UPDATE', 500, this.updateOperation)
  }

  async updateOperation() {
    try {
      await this.updateOperationNow()
    } catch (err) {
      session.pushError(err);
    }
    this.updating = false;
  }

  async updateOperationNow() {
    if (this.operation.operMode === OperTypeModes.Buy || this.operation.operMode === OperTypeModes.Sell) {
      await this.updateRate()
    }
    await this.updateFee()
  }

  async updateFee() {
    this.operation.operInAmount = this.inAmount
    this.operation.operOutAmount = this.outAmount

    const data = await wallet.getFee(this.operation)
    this.operation.fees = data.fees
    this.operation.operFeeCurrency = data.operFeeCurrency
    this.operation.operFeeAmount = data.operFeeAmount
    this.operation.operBaseFeeAmount = data.operBaseFeeAmount

    this.feeCurrency = wallet.currIndex[this.operation.operFeeCurrency || ''] || CURRENCY_DEFAULT()
    this.validate()
  }

  async updateRate() {
    if (this.operation.operInCurrency === this.operation.operOutCurrency) {
      this.rate = RATE_DEFAULT()
      this.rate.realRate = 1
      this.rate.customerRate = 1
    } else if (this.type && this.type.operDefaultGate !== null && this.type.operDefaultGate !== '') {
      let rq: IRateRequest;

      rq = {
        currBuy: this.inCurrency.currencyCode,
        currSell: this.outCurrency.currencyCode,
        currMain: this.mainCurrency.currencyCode,
        operType: this.type.operTypeCode,
        operMode: this.operation.operMode,
        useCrossRate: this.type.operUserCrossRate,
        gate: this.type.operDefaultGate,
      };

      try {
        this.rate = await wallet.getRate(rq)
      } catch (err) {
        if (!this.type.operUserCrossRate) {
          throw err
        } else {
          this.stopRateUpdate()
          this.rate = RATE_DEFAULT()
          this.rate.realRate = 0
          this.rate.customerRate = 0
          this.errGeneral = 'FRT017'
        }
      }

      if (this.isAmountIn) {
        this.recalcOut(false)
      } else {
        this.recalcIn(false)
      }
    }
    this.validate()
  }

  async setMethod(method: IPayMethod) {
    this.payMethod = method
    this.operation.payMethod = method

    let operType = this.operation.operTypeCode
    const payMethodTypeVariant = !method ? '%' : method.payMethodTypeVariant

    this.operModeSettingsList.forEach(settings => {
      if (settings.payMethodTypeVariant === payMethodTypeVariant) {
        operType = settings.operTypeCode
      }
    })

    if (operType !== this.operation.operTypeCode) {
      this.operation.operTypeCode = operType
      this.type = await wallet.getOperationTypeSettings(this.operation)
    }
  }

  async selectMethod(method: IPayMethod) {
    if (method.payMethodGlobal) {
      this.payMethod = method
      this.step = OperSteps.static
    } else {
      await this.setMethod(method)
      this.step = OperSteps.amount
    }
  }

  async selectCurrentAccount(item: IDashboardItem) {
    if (this.mode === OperTypeModes.Buy) {
      this.operation.operOutAccount = item.accountNumber
      this.operation.operOutCurrency = item.currencyCode
      this.operation.operMainCurrency = this.operation.operOutCurrency
      this.outCurrency = wallet.currIndex[this.operation.operOutCurrency || ''] || CURRENCY_DEFAULT()
      this.outAccount = wallet.accountIndex[this.operation.operOutAccount || ''] || ACCOUNT_DEFAULT()
    } else {
      this.operation.operInAccount = item.accountNumber
      this.operation.operInCurrency = item.currencyCode
      this.operation.operMainCurrency = this.operation.operInCurrency
      this.inCurrency = wallet.currIndex[this.operation.operInCurrency || ''] || CURRENCY_DEFAULT()
      this.inAccount = wallet.accountIndex[this.operation.operInAccount || ''] || ACCOUNT_DEFAULT()
    }
    await this.updateRate()
    this.rateUpdator = setInterval(() => { this.updateRate() }, 5000)
    this.step = OperSteps.amount
    this.validate()
  }

  clearMethod() {
    this.payMethod = null
  }

  putMethod(settings: IPayMethodSettings) {
    this.payMethodSettings = settings;
    this.payMethodEdit = {
      userPayMethodId: null,
      payMethodGlobal: false,
      payMethodNumber: '',
      payMethodType: settings.payMethodType,
      payMethodName: '',
      payMethodNetwork: '',
      payMethodExpired: new Date().add(100, 'YEAR'),
      payMethodCountry: '',
      payMethodToken: '',
      payMethodIssuerCode: '',
      payMethodIssuerName: '',
      payMethodReceiverName: '',
      payMethodReceiverAddress: '',
      payMethodMemo: '',
      payMethodFavoriteBuy: false,
      payMethodFavoriteSell: false,
      payMethodFavoriteSend: false,
      payMethodFavoriteGet: false,
      payMethodFavoriteTopup: false,
      payMethodFavoriteWithdraw: false,
      payMethodTypeVariant: settings.payMethodTypeVariant,
      payMethodState: ProcessStates.Done,
      payMethodFee: '',
      payMethodFeeMin: '',
      payMethodIcon: null,
      payMethodInfo: '',
      payMethodCurrency: 'XXX',
      type: wallet.payMethodsVariantsIndex[settings.payMethodTypeVariant] || PAY_TYPE_DEFAULT(),
      removing: false,
      processing: false,
    }
    this.showMethodEdit = true;
    this.error = ''
    this.processing = true
    this.$nextTick(() => { this.openPutMethod() })
  }

  async openPutMethod() {
    this.networks = await wallet.getFinanceNetworks(this.outCurrency.currencyCode)
    this.step = OperSteps.putmethod
  }

  afterMethodStore(method: IPayMethod) {
    this.processing = false
    this.selectMethod(method)
  }

  doneLoad() {
    this.processing = false
  }

  setCardError(err: any) {
    if (err === null) {
      this.processing = false
      this.errCard = ''
    } else {
      this.storeMethod = false
      this.processing = false
      if (err.errorCode) {
        this.errCard = $ph.i18n('system.errors.' + err.errorCode)
      } else {
        this.errCard = err.message
      }
    }
  }

  async processOperation() {
    this.processing = true;
    try {
      let payMethodSettings: IPayMethodSettings | undefined | null = null
      if (this.payMethod) {
        payMethodSettings = this.payMethodSettingsList.find(s => s.payMethodTypeVariant === this.payMethod!.payMethodTypeVariant)
      }

      this.operation.payMethod = this.payMethod
      this.operation.operToken = this.payMethod ? this.payMethod.payMethodToken : null
      this.operation.operInWallet = this.payMethod && this.payMethod.payMethodType === PaymentMethodType.Blockchain ? this.payMethod.payMethodNumber : null
      this.operation.operGate = payMethodSettings && payMethodSettings.payMethodGate ? payMethodSettings.payMethodGate : this.type!.operDefaultGate;
      this.operation.operGateChannel = payMethodSettings && payMethodSettings.payMethodChannel ? payMethodSettings.payMethodChannel : null;
      this.operation.operGateIn = this.type!.operDefaultGate;

      this.operation.operOutAmount = this.outAmount
      this.operation.operInAmount = this.inAmount
      this.operation.operRate = this.rate ? this.rate.realRate : 1

      if (this.mode === OperTypeModes.Topup) {
        this.operation.operDescr = 'Account toupup'
      } else if (this.mode === OperTypeModes.Buy) {
        this.operation.operDescr = 'Buy currency ' + this.operation.operMainCurrency
      } else if (this.mode === OperTypeModes.Sell) {
        this.operation.operDescr = 'Sell currency ' + this.operation.operMainCurrency
      } else if (this.mode === OperTypeModes.Withdraw || this.mode === OperTypeModes.Send) {
        this.operation.operDescr = this.payMethod ? 'Send to ' + this.payMethod.payMethodNumber : 'Withrawal from account'
      }

      this.operation.operOtpCheck = {
        code: this.checkCode,
        factorId: null,
        provider: 'GOOGLE',
      }

      this.operation.operBrowserData = JSON.stringify({
        acceptHeaders: 'text/html,application/xhtml+xml,application/xml',
        javaEnabled: true,
        language: navigator.language,
        colorDepth: 48,
        screenWidth: screen.width,
        screenHeight: screen.height,
        timeZoneOffsetMin: new Date().getTimezoneOffset(),
        userAgent: navigator.userAgent,
      })

      const result: IOperationFull = await wallet.makeOperation(this.operation)
      this.checkCode = ''

      if (result.operState === ProcessStates.Failed) {
        throw new Error($ph.i18n('system.errors.FRT002'))
      } else if (result.operState === ProcessStates.Done) {
        this.step = OperSteps.success
      } else if (result.redirectUrl && result.redirectUrl !== '') {
        if (settings.company.companyUISettings.global.fullRedirect) {
          window.location.href = result.redirectUrl
        } else {
          this.redirectUrl = result.redirectUrl;
          this.redirectVisible = true;
          this.step = OperSteps.background
          $ph.delay('OPER_CHECK_STATE', 5000, () => this.checkOperationState(result.operReference))
        }
      } else {
        this.step = OperSteps.background
      }
    } catch (e) {
      this.showError(e)
    }
    this.processing = false;
  }

  showError(err: any) {
    this.stopRateUpdate();

    if (err.errorCode) {
      const msg = $ph.i18n('system.errors.' + err.errorCode)
      if (msg === 'system.errors.' + err.errorCode) {
        this.error = err.errorText ? err.errorText : 'Internal system error'
      } else {
        this.error = msg
      }
    } else {
      this.error = err.message
    }

    if (err.errorCode === 'SYS007') {
      this.checkCode = ''
      this.step = OperSteps.secure
    } else if (err.errorCode === 'SYS013') {
      this.error = ''
      this.step = OperSteps.background
    } else {
      if (err.errorCode === 'FIN013') {
        this.errorMessage = $ph.i18n('system.errors.' + err.errorCode)
      } else {
        this.errorMessage = $ph.i18n('payment.failed')
      }
      this.step = OperSteps.failed
    }
  }

  async checkOperationState(ref: string) {
    try {
      const result: IOperationFull = await wallet.getOperation(ref)
      if (result.operState === ProcessStates.Failed) {
        this.redirectVisible = true;
        throw new Error($ph.i18n('system.errors.FRT002'))
      } else if (result.operState === ProcessStates.Done) {
        this.redirectVisible = true;
        this.step = OperSteps.success
      } else {
        $ph.delay('OPER_CHECK_STATE', 2000, () => this.checkOperationState(ref))
      }
    } catch (e) {
      this.showError(e)
    }
  }

  refresh() {
    this.$emit('refresh', this.operation)
  }
}
