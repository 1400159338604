
import { AccountStates, AccountTypeModes, IDashboardItem } from '../types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { wallet } from '@/plugins/store';

@Component
export default class CurrentAccountsList extends Vue {
  @Prop() readonly skipCurrency: string | undefined;

  get items(): Array<IDashboardItem> {
    console.log('CURR', this.skipCurrency)
    return wallet.dashboard.filter(d => d.account &&
      d.account.accountTypeMode === AccountTypeModes.CustomerCurrent &&
      d.account.accountState === AccountStates.Active &&
      d.account.accountCurrency !== this.skipCurrency) || null
  }

  select(value: IDashboardItem) {
    this.$emit('select', value)
  }
}

